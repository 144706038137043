import React from "react";

import { Icon } from "../Icon/Icon";
import cs from "classnames";
import { IconDefinition } from "../Icon/Icon.generated";

import "./InputField.scss";

interface InputFieldProps {
  name?: string;
  leftIcon?: IconDefinition;
  rightIcon?: IconDefinition;
  placeholder?: string;
  type?: string;
  disabled?: boolean;
  value?: string;
  onBlur: (e: any) => void;
  onChange: (e: any) => void;
  onIconClick?: () => void;
  className?: string;
  style?: React.CSSProperties;
  error?: boolean;
}

const InputField = (props: InputFieldProps) => {
  const {
    onIconClick,
    name,
    leftIcon,
    rightIcon,
    placeholder,
    type,
    disabled,
    value,
    onBlur,
    onChange,
    style,
    error,
    className,
  } = props;
  return (
    <div className={cs("InputField", className)}>
      <div
        className={cs(
          "InputField__container",
          disabled && `InputField__container--disabled`,
          error && `InputField__container--error`
        )}
      >
        {leftIcon && (
          <Icon
            onClick={() => {}}
            className={cs("InputField__icon", `InputField__icon--left`)}
            icon={leftIcon}
          />
        )}
        <input
          name={name}
          style={style}
          placeholder={placeholder}
          className={cs(
            "InputField__input",
            disabled && `InputField__input--disabled`
          )}
          type={type}
          disabled={disabled}
          value={value}
          onBlur={onBlur}
          onChange={onChange}
        />
        {rightIcon && (
          <Icon
            onClick={onIconClick}
            className={cs("InputField__icon", `InputField__icon--right`)}
            icon={rightIcon}
          />
        )}
      </div>
    </div>
  );
};

export default InputField;
