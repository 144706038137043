import React from "react";

import "./AuthAlert.scss";
import { Icon } from "../Icon/Icon";
import cs from "classnames";

interface AuthAlertProps {
  title?: string | null;
  subtitle?: string | null;
  type?: string;
}

const AuthAlert = (props: AuthAlertProps) => {
  const { title, subtitle, type } = props;

  return (
    <div className={cs("AuthAlert", type && `AuthAlert--${type}`)}>
      <div className="AuthAlert__header">
        <Icon
          onClick={() => {}}
          className={cs("AuthAlert__icon", type && `AuthAlert__icon--${type}`)}
          icon="exclamation_circle"
        />
        <h3 className="AuthAlert__header-title">{title}</h3>
      </div>
      <p className="AuthAlert__subtitle">{subtitle}</p>
    </div>
  );
};

export default AuthAlert;
