import React from "react";

import { useSidebarContext } from "../../../lib/context/SidebarContext/SidebarContext";
import { Icon } from "../../shared/Icon/Icon";

import "./HamburgerButton.scss";

const HamburgerButton = () => {
  const changeSidebarVisibility = useSidebarContext().changeSidebarVisibility;

  return (
    <div
      className="HamburgerButton"
      onClick={() => {
        changeSidebarVisibility();
      }}
    >
      <Icon
        className="HamburgerButton__icon"
        onClick={() => {}}
        icon="hamburger-icon"
      />
    </div>
  );
};

export default HamburgerButton;
