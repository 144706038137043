import React, { useState } from "react";

import { Icon, IconDefinition } from "../../shared/Icon/Icon";
import { useSidebarContext } from "../../../lib/context/SidebarContext/SidebarContext";
import cs from "classnames";
import { useLocation, useNavigate } from "react-router-dom";

import "./LevelOne.scss";

interface LevelOnePropsType {
  isOpen?: boolean;
  icon?: IconDefinition;
  label?: string;
  onClick: () => void;
  hasChildItems?: boolean;
  childItems?: any;
  path?: string;
  isHeader?: boolean;
  id?: string;
}

const LevelOne = (props: LevelOnePropsType) => {
  const { isOpen, onClick, icon, label, path, hasChildItems, isHeader, id } =
    props;

  const [showLevelOneChild, setShowLevelOneChild] = useState(false);
  const isSidebarOpen = useSidebarContext().isSidebarOpen;
  const navigate = useNavigate();
  const location = useLocation();
  const url = location.pathname;
  const splitUrl = url.split("/");

  return (
    <>
      {isHeader ? (
        <div
          className={cs(
            "LevelOne__header",
            !isSidebarOpen && "LevelOne__header-collapsed"
          )}
        >
          {isSidebarOpen ? (
            <p className="LevelOne__header-label">{label}</p>
          ) : (
            <div className="LevelOne__header-icon">
              <div className="LevelOne__header-circle"></div>
              <div className="LevelOne__header-circle"></div>
              <div className="LevelOne__header-circle"></div>
            </div>
          )}
        </div>
      ) : (
        <div
          className="LevelOne"
          onMouseEnter={() => {
            setShowLevelOneChild(true);
          }}
          onMouseLeave={() => {
            setShowLevelOneChild(false);
          }}
        >
          <div className="LevelOne__container">
            <div
              className={cs(
                "LevelOne__head",
                !isSidebarOpen && "LevelOne__head--collapsed"
              )}
              onClick={() => {
                onClick();
              }}
            >
              <div className="LevelOne__head-container">
                <Icon
                  className={cs(
                    "LevelOne__icon",
                    isOpen && isSidebarOpen && "LevelOne__icon--active",
                    showLevelOneChild && "LevelOne__icon--active",
                    /*  location.pathname === path  */ path?.endsWith(
                      `/${splitUrl[1]}`
                    ) && "LevelOne__icon--active",
                    id === splitUrl[1] && "LevelOne__icon--active"
                  )}
                  onClick={() => {}}
                  icon={icon}
                />
                {isSidebarOpen && (
                  <div
                    className={cs(
                      "LevelOne__label",
                      isOpen && "LevelOne__label--active",
                      path?.endsWith(`/${splitUrl[1]}`) &&
                        "LevelOne__label--active"
                    )}
                  >
                    {label}
                  </div>
                )}
              </div>

              {isSidebarOpen && hasChildItems && (
                <Icon
                  className={cs(
                    "LevelOne__arrow",
                    isOpen && "LevelOne__arrow--open"
                  )}
                  onClick={() => {}}
                  icon="chevron-down"
                />
              )}
            </div>
            <div
              className={cs(
                "LevelOne__body",
                isOpen && isSidebarOpen && "LevelOne__body--open",
                !isSidebarOpen &&
                  showLevelOneChild &&
                  "LevelOne__body--collapsed-open"
              )}
            >
              {!isSidebarOpen && (
                <div
                  className="LevelOne__body-head"
                  onClick={() => {
                    if (!hasChildItems) {
                      navigate(`${path}`);
                    }
                  }}
                >
                  <div className="LevelOne__body-label">{label}</div>
                  {hasChildItems && (
                    <Icon
                      className="LevelOne__body-arrow"
                      onClick={() => {}}
                      icon="chevron-down"
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default LevelOne;
