import React from "react";
import cs from "classnames";
import { Icon } from "../Icon/Icon";
import { IconDefinition } from "../Icon/Icon.generated";

import "./Button.scss";

interface ButtonProps {
  label?: string;
  style?: React.CSSProperties;
  className?: string;
  onClick: () => void;
  type?: "submit" | "reset" | "button";
  form?: string;
  isLoading?: boolean;
  id?: string;
  disabled?: boolean;
  icon?: IconDefinition;
}

const Button = (props: ButtonProps) => {
  const { disabled, isLoading, label, style, className, type, id, icon } =
    props;

  return (
    <button
      disabled={disabled}
      type={type}
      id={id}
      onClick={() => {
        props.onClick();
      }}
      style={style}
      className={cs("Button", className, disabled && `Button--disabled`)}
    >
      {icon && <Icon icon={icon} onClick={() => {}} className="Button__icon" />}
      {isLoading ? (
        <div className="Button__spinner" />
      ) : (
        <>{label && <div className="Button__label">{label}</div>}</>
      )}
    </button>
  );
};

export default Button;
