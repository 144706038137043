import React from "react";

import { Icon } from "../../shared/Icon/Icon";
import Button from "../../shared/Button/Button";
import cs from "classnames";
import PopUp from "../PopUp/PopUp";

import "./MessageModal.scss";

interface messageModalProps {
  title: string;
  description: string;
  typeOfModal: string;
  isOpen: boolean;
  toggle: () => void;
  onCancel: () => void;
  onAccept: () => void;
}

const MessageModal = (props: messageModalProps) => {
  const { title, description, typeOfModal } = props;
  return (
    <PopUp showPopUp={props.isOpen} togglePopUp={props.toggle}>
      <div className={cs("MessageModal", `MessageModal-${typeOfModal}`)}>
        <Icon
          onClick={props.toggle}
          className="MessageModal__closeIcon"
          icon="close"
        />
        <div className="MessageModal__content">
          {typeOfModal === "success" ? (
            <Icon
              onClick={props.toggle}
              className={cs("MessageModal__icon", `MessageModal__icon-success`)}
              icon="green-bg-check"
            />
          ) : (
            <Icon
              onClick={props.toggle}
              className={cs(
                "MessageModal__icon",
                `MessageModal__icon--${typeOfModal}`
              )}
              icon="exclamation_circle"
            />
          )}

          <h4
            className={cs(
              "MessageModal__title",
              `MessageModal__title-${typeOfModal}`
            )}
          >
            {title}
          </h4>
          <p className="MessageModal__paragraph">{description}</p>
          <div className="MessageModal__buttons">
            <Button
              className={cs(
                "Button__primary--outline",
                `MessageModal__cancel-button`
              )}
              label="Cancel"
              onClick={props.onCancel}
            />
            <Button
              className={cs(
                `Button`,
                "MessageModal__accept-button",
                `MessageModal__accept-button--${typeOfModal}`
              )}
              label="OK"
              onClick={props.onAccept}
            />
          </div>
        </div>
      </div>
    </PopUp>
  );
};
export default MessageModal;
