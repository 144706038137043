import { createContext, useContext } from "react";

export interface SidebarContextType {
  isSidebarOpen: boolean;
  changeSidebarVisibility: () => void;
}

const SidebarContextValues: SidebarContextType = {
  isSidebarOpen: true,
  changeSidebarVisibility: () => {},
};

export const SidebarContext =
  createContext<SidebarContextType>(SidebarContextValues);
export const useSidebarContext = () => useContext(SidebarContext);
