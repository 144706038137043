import React from "react";
import { Route, Routes as Routings } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import { PrivateRoutes, PublicRoutes } from "./AppRoutes";
import { useAuthContext } from "../lib/context/AuthContext/AuthContext";

const Routes = () => {
  const authCtx = useAuthContext();
  const isAuth = authCtx.isAuthenticated;
  let localStorageAuth = localStorage.getItem("localStorageAuth")!!;

  return (
    <>
      <Routings>
        <Route
          element={
            <PrivateRoute isAuth={isAuth} localStorageAuth={localStorageAuth} />
          }
        >
          {PrivateRoutes.map((r) => (
            <Route element={r.element} key={r.path} path={r.path} />
          ))}
        </Route>

        {PublicRoutes.map((r) => (
          <Route element={r.element} key={r.path} path={r.path} />
        ))}
      </Routings>
    </>
  );
};

export default Routes;
