import React, { useEffect, useState } from "react";
import { SidebarContext, SidebarContextType } from "./SidebarContext";
import { useResponsiveDimensions } from "../../hooks/useResponsiveDimensions";

interface CustomizerContextProviderProps {
  children: React.ReactNode | null;
}

export const SideBarContextProvider = (
  props: CustomizerContextProviderProps
) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const isMobile = useResponsiveDimensions().isMobile;

  useEffect(() => {
    if (isMobile) {
      setIsSidebarOpen(false);
    }
  }, []);

  const changeSidebarVisibility = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const context: SidebarContextType = {
    isSidebarOpen,
    changeSidebarVisibility,
  };

  return (
    <SidebarContext.Provider value={context}>
      {props.children}
    </SidebarContext.Provider>
  );
};
